.slide-bar {
  position: relative;
  padding: 10px;
  overflow: hidden; /* Ensure container hides overflow for clean appearance */
}

.promotion-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.promotion-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 200px;
  width: 100%;
  margin-right: 10px;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.scroll-button-left {
  left: 0;
}

.scroll-button-right {
  right: 0;
}

/* Ensure buttons are visible on smaller screens */
@media (max-width: 600px) {
  .scroll-button {
    padding: 5px;
  }

  .scroll-button-left {
    left: 5px;
  }

  .scroll-button-right {
    right: 5px;
  }
}
